import {AnswerRequest} from "./Pojo";

export const answerRequest : AnswerRequest = {
  ps: '',
  choices: []
}

export const passingData = {
  catId: 0,
}

export type IdRouteProp = {
  id?: string
}