import '../css/header.css';
import '../css/leaderboard.css';
import React, {useEffect, useState} from "react";
import {Leaderboard, MQResponse} from "../Pojo";
import axios from "axios";
import {PointHeader} from "../component/PointHeader";


export const LeaderboardPage = () => {

  const [data, setData] = useState<MQResponse>();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    axios.get<MQResponse>("/api/user/leaderboard").then((response) => {
      setData(response.data);
    });
  }, []);

  let leaderboard = data ? data.data as Leaderboard : undefined;
  let user = data ? data.user : undefined;

  return (
    <div className="content leaderboard">
      <PointHeader title="LEADERBOARD" user={user}/>
      <div className="leaderboard-top">
        <h5><b>{leaderboard ? leaderboard.name : '...'}</b>, kamu punya</h5>
        <div className="lt-inner">
        <span>
          <div>Poin</div>
          <div>{leaderboard ? leaderboard.point : 0}</div>
        </span>
          {/*<span>
          <div>Token</div>
          <div>{leaderboard ? leaderboard.token : 0}</div>
        </span>*/}
        </div>
      </div>
      <img src={leaderboard && leaderboard.banner}/>
      <div className="leaderboard-bottom">
        <div className="lb-wrapper">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a onClick={()=>{setTab(1);}} className={"nav-link " + (tab == 1 ? 'active' : '')} id="token-tab" data-toggle="tab" role="tab"
                 aria-controls="token" aria-selected="true">Point</a>
            </li>
            {/*<li className="nav-item">
              <a onClick={()=>{setTab(2);}} className={"nav-link " + (tab == 2 ? 'active' : '')} id="livequiz-tab" data-toggle="tab" role="tab"
                 aria-controls="livequiz" aria-selected="false">LIVE QUIZ</a>
            </li>*/}
          </ul>
          <div className="tab-content" id="myTabLeaderboard">
            <div className={"tab-pane fade " + (tab == 1 ? 'show active' : '')} id="token" role="tabpanel" aria-labelledby="token-tab">

              {leaderboard &&
              <div className="lb-inner">
                  <div className="lbi-head">{leaderboard.topfive.title}</div>
                  <table className="table-top5">
                      <tr>
                          <th>No.</th>
                          <th>Nama</th>
                          <th>Skor</th>
                      </tr>

                    {
                      leaderboard.topfive.ranks.map((value, index) => {
                        return <tr>
                          <td>{value.no}</td>
                          <td>{value.name}</td>
                          <td>{value.point}</td>
                        </tr>
                      })
                    }
                  </table>
              </div>
              }

              {leaderboard && leaderboard.winner &&
              <div className="lb-inner">
                  <div className="lbi-head">{leaderboard.winner.title}</div>
                  <table className="table-winner-m">
                      <tr>
                          <th>Nama</th>
                          <th>Point</th>
                          <th>Hadiah</th>
                      </tr>

                    {leaderboard.winner.winners.map((value, index) => {
                      return <tr>
                        <td>{value.name}</td>
                        <td>{value.point}</td>
                        <td>{value.hadiah}</td>
                      </tr>

                    })
                    }

                  </table>
              </div>

              }

            </div>
            <div className={"tab-pane fade " + (tab == 2 ? 'show active' : '')} id="livequiz" role="tabpanel" aria-labelledby="livequiz-tab">
              {leaderboard && leaderboard.live &&
              <div className="lb-inner">
                  <div className="lbi-head">{leaderboard.live.title}</div>
                  <table className="table-winner-lq">
                      <thead>
                      <tr>
                          <th>Nama</th>
                          <th>Hadiah</th>
                      </tr>
                      </thead>
                    <tbody>
                    {
                      leaderboard.live.winners.map((value => {
                        return <tr>
                          <td>{value.name}</td>
                          <td>{value.hadiah}</td>
                        </tr>
                      }))
                    }
                    </tbody>
                  </table>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}