import {Operator} from "./Operator";

export const ISAT: Operator = {
  "name": "isat",
  "charging": {
    //"reg": "http://9386.me/3640",
    "reg": "http://indosat.triyakom.com/?kw=REG%20MQ200%20WEB&sdc=99386&direct=http%3A%2F%2Fklik.123xfun.com%2Fsubs%2Fl%2Ft.aspx",
    "sms": "REG MQ200",
    "powerUp": "",
    "addPlay1": "",
    "addPlay2": ""
  },
  "join": {
    "image": "https://s.tykcdn.com/i/mq/images/sample/Banner_Join-Page.png",
    "greeting": "Main Matchquiznya dan dapatkan hadiah Total Jutaan Rupiah!",
    "subtitle": "Klik gabung sekarang",
    "smsMessage": "Ketik Reg <b>MQ200</b> kirim ke <b>99386</b> (khusus pelanggan Indosat) cuma Rp 2.000/3 hari."
  },
}