export const QuestLoading = ()=>{
  return(
  <div className="loading-q">
  <div className="lq-text">Loading...</div>
  <div className="meter animate">
    <span><span></span></span>
  </div>
  <div className="lq-bottom">Mohon tunggu. Kami sedang membuka buku olahraga..</div>
  </div>
);

}