import React, {Component, Fragment} from "react";
import {Question} from "../Pojo";
import {resourcePrefix} from "../Constant";
import axios from "axios";

type QuestFragmentProps = {
  quest: Question,
  power: number,
  timeout: () => void,
  answer: (qids:string, aids: string) => void
}

type QuestState = {
  timer: number,
  offset: number,
  power: number,
  answerClass: string[]
}

export class QuestionFragment extends Component<QuestFragmentProps, QuestState> {

  intervalNumber?: number;
  maxtime = 10;
  time = this.maxtime;
  isAnswer = false;

  constructor(props: QuestFragmentProps) {
    super(props);
    this.state = {timer: 0, offset: 150, power: props.power, answerClass: ['', '', '', '']};
    console.log('CREATED')
  }

  componentDidMount() {
    let ix = 150-(1*(150/this.time));;
    this.setState({offset: 0});
    console.log('>>', ix);
    this.intervalNumber = window.setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  tick() {
    let i = this.state.timer;
    if (i == this.time) {
      this.timeout();
    }else {
      //let _offset = (150 - ((i + 1) * (150 / this.time)));
      let _offset = this.state.offset + 150 / this.maxtime;
      this.setState({timer: i + 1, offset: Math.abs(Math.floor(_offset)) });
    }
    console.log('>>', this.state.offset);
  }

  timeout = ()=>{
    this.stopTimer();
    this.props.timeout();
}

  stopTimer = ()=>{
    window.clearInterval(this.intervalNumber);
  }

  fifty2 = ()=>{
    if(this.state.power <= 0) return;
    axios.get('/api/quest/powerup/fifty').then((response) =>{

    });
    var d = [0, 1, 2, 3];
    this.shuffle(d);

    var p = this.state.answerClass;
    p[d[0]] = 'hide';
    p[d[1]] = 'hide';

    this.setState({power: this.state.power - 1, answerClass: p})
  }

  shuffle = (array: number[]) => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  addTime = () =>{
    console.log('add timne')
    if(this.state.power <= 0) return;
    axios.get('/api/quest/powerup/fifty').then((response) =>{

    });
    this.time += 5;
    this.maxtime += 5;
    this.setState({power: this.state.power - 1})
  }


  answer(index: number) {
    var p = this.state.answerClass;
    if(this.isAnswer || p[index] == 'hide') return;
    this.isAnswer = true;

    for (let i = 0; i < p.length; i++) {
      if(p[i] == '') p[i] = ' hide';
    }
    p[index] = 'correct';
    this.setState({answerClass: p});
    this.stopTimer();
    setTimeout(()=>{
      this.props.answer(this.props.quest.qids, this.props.quest.answers[index].aids);
    }, 300);
  }

  render() {
    return (
      <Fragment>
        <div className="qm-head">
          <button onClick={this.addTime} className={'btn ' + (this.props.power > 0 ? '' : 'sup-selected')}>
            Tambah waktu
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
              <g transform="translate(-158 -171)">
                <rect className="fill-o" width="27" height="27" rx="5" transform="translate(158 171)"/>
                <g className="fill-w" transform="translate(691.758 -374.412)">
                  <g transform="translate(-528.758 551.411)">
                    <path
                      d="M-514.845,557.171a6.971,6.971,0,0,0-.767-2.157,6.974,6.974,0,0,0-1.811-2.117,6.993,6.993,0,0,0-2.4-1.216,6.993,6.993,0,0,0-2.68-.228,6.975,6.975,0,0,0-2.654.847,6.976,6.976,0,0,0-2.117,1.811,6.993,6.993,0,0,0-1.216,2.4,6.992,6.992,0,0,0-.228,2.68,6.973,6.973,0,0,0,.848,2.654,6.97,6.97,0,0,0,1.811,2.117,7,7,0,0,0,2.4,1.216,7.012,7.012,0,0,0,1.92.269,7.008,7.008,0,0,0,.759-.041l-.1-.886a6.106,6.106,0,0,1-2.339-.2,6.105,6.105,0,0,1-2.094-1.062,6.085,6.085,0,0,1-1.581-1.848,6.09,6.09,0,0,1-.74-2.317,6.1,6.1,0,0,1,.2-2.339,6.109,6.109,0,0,1,1.062-2.094,6.09,6.09,0,0,1,1.848-1.58,6.089,6.089,0,0,1,2.316-.74,6.113,6.113,0,0,1,2.34.2,6.11,6.11,0,0,1,2.094,1.062,6.089,6.089,0,0,1,1.58,1.848,6.115,6.115,0,0,1,.636,1.679l-1.291-.064,1.624,3.163,1.927-2.988Z"
                      transform="translate(528.758 -551.411)"/>
                    <g transform="translate(3.127 3.865)">
                      <path
                        d="M-514.281,568.983a.444.444,0,0,1-.214-.055l-3.482-1.909a.446.446,0,0,1-.176-.605.445.445,0,0,1,.605-.177l3.2,1.755,3.7-3.424a.446.446,0,0,1,.63.025.446.446,0,0,1-.025.63l-3.94,3.642A.447.447,0,0,1-514.281,568.983Z"
                        transform="translate(518.208 -564.449)"/>
                    </g>
                    <g transform="translate(8.641 10.178)">
                      <path
                        d="M-498.741,589.038h-.869v-.662h.869v-.864h.682v.864h.869v.662h-.869v.864h-.682Z"
                        transform="translate(499.61 -586.988)"/>
                      <path
                        d="M-490.455,588.822l.447-.533a1.176,1.176,0,0,0,.8.365c.346,0,.562-.168.562-.465v-.01c0-.288-.245-.456-.6-.456a1.44,1.44,0,0,0-.562.125l-.427-.283.1-1.656h2.064v.648H-489.5l-.038.581a1.65,1.65,0,0,1,.441-.057,1.034,1.034,0,0,1,1.176,1.075v.009a1.145,1.145,0,0,1-1.277,1.162A1.728,1.728,0,0,1-490.455,588.822Z"
                        transform="translate(493.169 -585.86)"/>
                      <path
                        d="M-481.17,588.665l.437-.523a1.543,1.543,0,0,0,1,.408c.3,0,.485-.12.485-.317v-.01c0-.187-.115-.283-.677-.427-.677-.173-1.114-.36-1.114-1.027v-.009a1.051,1.051,0,0,1,1.176-1.013,1.945,1.945,0,0,1,1.248.428l-.384.557a1.575,1.575,0,0,0-.874-.331c-.283,0-.432.129-.432.293v.009c0,.221.144.293.725.442.682.178,1.066.423,1.066,1.008v.01c0,.667-.509,1.042-1.234,1.042A2.14,2.14,0,0,1-481.17,588.665Z"
                        transform="translate(486.637 -585.746)"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <button onClick={this.fifty2} className={'btn ' + (this.props.power > 0 ? '' : 'sup-selected')}>
            Filter
            <svg xmlns="http://www.w3.org/2000/svg" width="59" height="23" viewBox="0 0 59 23">
              <g transform="translate(-302 -177)">
                <g className="fill-w stroke-o" transform="translate(302 177)">
                  <path
                    d="M 54 22.5 L 5 22.5 C 2.51869010925293 22.5 0.5 20.48130989074707 0.5 18 L 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 L 54 0.5 C 56.4813117980957 0.5 58.5 2.51869010925293 58.5 5 L 58.5 18 C 58.5 20.48130989074707 56.4813117980957 22.5 54 22.5 Z"
                    stroke="none"/>
                  <path
                    d="M 5 1 C 2.794391632080078 1 1 2.794389724731445 1 5 L 1 18 C 1 20.20561027526855 2.794391632080078 22 5 22 L 54 22 C 56.20560836791992 22 58 20.20561027526855 58 18 L 58 5 C 58 2.794389724731445 56.20560836791992 1 54 1 L 5 1 M 5 0 L 54 0 C 56.76142120361328 0 59 2.238580703735352 59 5 L 59 18 C 59 20.76141929626465 56.76142120361328 23 54 23 L 5 23 C 2.238571166992188 23 0 20.76141929626465 0 18 L 0 5 C 0 2.238580703735352 2.238571166992188 0 5 0 Z"
                    stroke="none" fill="#fff"/>
                </g>
                <path className="fill-o" d="M0,0H25a5,5,0,0,1,5,5V18a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                      transform="translate(331 177)" fill="#fff"/>
                <path className="fill-o"
                      d="M.838-3.5,1.289-7.82h4.13v1.128H2.374l-.236,1.96a2.073,2.073,0,0,1,.534-.282,2.181,2.181,0,0,1,.685-.121,2.146,2.146,0,0,1,1.724.7A2.835,2.835,0,0,1,5.7-2.5a2.709,2.709,0,0,1-.655,1.88,2.415,2.415,0,0,1-1.9.73A2.71,2.71,0,0,1,1.41-.454,1.871,1.871,0,0,1,.725-2.062l.011-.027,1.219-.043a1.165,1.165,0,0,0,.333.886,1.2,1.2,0,0,0,.865.317,1.086,1.086,0,0,0,.927-.427A1.841,1.841,0,0,0,4.4-2.487a1.89,1.89,0,0,0-.328-1.152,1.093,1.093,0,0,0-.935-.443,1.452,1.452,0,0,0-.8.175.986.986,0,0,0-.379.5Zm11.1.483a3.474,3.474,0,0,1-.687,2.326,2.336,2.336,0,0,1-1.869.8,2.358,2.358,0,0,1-1.88-.8,3.456,3.456,0,0,1-.693-2.326V-4.8A3.487,3.487,0,0,1,7.5-7.127a2.335,2.335,0,0,1,1.875-.806,2.345,2.345,0,0,1,1.875.806A3.469,3.469,0,0,1,11.94-4.8ZM10.635-5a2.512,2.512,0,0,0-.322-1.423,1.074,1.074,0,0,0-.94-.462,1.067,1.067,0,0,0-.937.462A2.529,2.529,0,0,0,8.116-5v2.175a2.521,2.521,0,0,0,.325,1.431,1.079,1.079,0,0,0,.943.465,1.06,1.06,0,0,0,.935-.462,2.57,2.57,0,0,0,.317-1.434Z"
                      transform="translate(312 192)" fill="#fff"/>
                <path className="fill-w"
                      d="M.838-3.5,1.289-7.82h4.13v1.128H2.374l-.236,1.96a2.073,2.073,0,0,1,.534-.282,2.181,2.181,0,0,1,.685-.121,2.146,2.146,0,0,1,1.724.7A2.835,2.835,0,0,1,5.7-2.5a2.709,2.709,0,0,1-.655,1.88,2.415,2.415,0,0,1-1.9.73A2.71,2.71,0,0,1,1.41-.454,1.871,1.871,0,0,1,.725-2.062l.011-.027,1.219-.043a1.165,1.165,0,0,0,.333.886,1.2,1.2,0,0,0,.865.317,1.086,1.086,0,0,0,.927-.427A1.841,1.841,0,0,0,4.4-2.487a1.89,1.89,0,0,0-.328-1.152,1.093,1.093,0,0,0-.935-.443,1.452,1.452,0,0,0-.8.175.986.986,0,0,0-.379.5Zm11.1.483a3.474,3.474,0,0,1-.687,2.326,2.336,2.336,0,0,1-1.869.8,2.358,2.358,0,0,1-1.88-.8,3.456,3.456,0,0,1-.693-2.326V-4.8A3.487,3.487,0,0,1,7.5-7.127a2.335,2.335,0,0,1,1.875-.806,2.345,2.345,0,0,1,1.875.806A3.469,3.469,0,0,1,11.94-4.8ZM10.635-5a2.512,2.512,0,0,0-.322-1.423,1.074,1.074,0,0,0-.94-.462,1.067,1.067,0,0,0-.937.462A2.529,2.529,0,0,0,8.116-5v2.175a2.521,2.521,0,0,0,.325,1.431,1.079,1.079,0,0,0,.943.465,1.06,1.06,0,0,0,.935-.462,2.57,2.57,0,0,0,.317-1.434Z"
                      transform="translate(340 192)" fill="#fd6200"/>
              </g>
            </svg>
          </button>
        </div>
        <div className="question-main-wrapper">
          <div className="qmw-top">
            <div className="timer">
              <h4>{this.time - this.state.timer}</h4>
              <svg xmlns="http://www.w3.org/2000/svg">
                <circle strokeDashoffset={this.state.offset + 'px'}
                        id="circle"
                        className="circle_animation" r="24" cy="29" cx="29"/>
              </svg>
            </div>
            <div className="qmw-top-img"><img
              src={resourcePrefix + this.props.quest.image}/></div>
            <div className="qmw-top-text">{this.props.quest.question}</div>
          </div>
        </div>
        <div className="qm-answer" id="myAnswer">
          {
            this.props.quest.answers.map((value, index) => {
              return <div onClick={() => {this.answer(index)}}
                          key={index} className={'qma-item ' + this.state.answerClass[index]}>{value.label}
              </div>
            })
          }

        </div>
      </Fragment>
    );
  }
}
