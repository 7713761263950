import {Operator} from "./Operator";

export const HTI : Operator = {
  name: "hti",
  charging: {
    "reg": "https://klik.123xfun.com/charging/hti?kc=REG%MQK&sdc=99386&desc=Kamu%20akan%20menerima%20SMS%20dari%2099386%20untuk%20berlangganan%20layanan%20MATCH%20QUIZ%20tariff%20Rp.%202000%2F%20SMS.&direct=http%3A%2F%2Fklik.123xfun.com%2Fsubs%2Fl%2Ft.aspx",
    "powerUp": "",
    "addPlay1": "",
    "addPlay2": ""
  },
  "join": {
    "image": "https://s.tykcdn.com/i/mq/images/sample/Banner_Join-Page.png",
    "greeting": "Main Matchquiznya dan dapatkan hadiah Total Jutaan Rupiah!",
    "subtitle": "Klik gabung sekarang",
    "smsMessage": "Ketik Reg <b>MQ</b> kirim ke <b>99386</b> (khusus pelanggan Indosat) cuma Rp 2.000/3 hari."
  },
}