import '../css/header-main.css';
import '../css/setting.css';

import {Link} from 'react-router-dom';
import axios from "axios";
import {useHistory} from "react-router";

export const SettingPage = () => {

  let history = useHistory();

  function logout(){
    axios.get('/api/user/logout').then(value => {
      history.push('/login');
    });
  }

  return (<div className="content setting">
      <div className="main-head">
        <button onClick={()=>{history.goBack()}} className="btn"><img src="https://s.tykcdn.com/i/mq/images/back.svg"/></button>
        <h6>SETTING</h6>
      </div>
      <div className="setting-btn sb-top">
        <Link to="/setting/snk" className="btn">
        <span>
          <div className="sb-img"><img src="https://s.tykcdn.com/i/mq/images/snk.svg"/></div>
          Syarat &amp; Ketentuan
        </span>
          <span>
          <img src="https://s.tykcdn.com/i/mq/images/chevron-right.svg"/>
        </span>
        </Link>
        <Link to="/setting/faq" className="btn">
        <span>
          <div className="sb-img"><img src="https://s.tykcdn.com/i/mq/images/faq.svg"/></div>
          FAQ
        </span>
          <span>
          <img src="https://s.tykcdn.com/i/mq/images/chevron-right.svg"/>
        </span>
        </Link>
        <Link to="/setting/profile" className="btn">
        <span>
          <div className="sb-img"><img src="https://s.tykcdn.com/i/mq/images/edit.svg"/></div>
          Edit Profile
        </span>
          <span>
          <img src="https://s.tykcdn.com/i/mq/images/chevron-right.svg"/>
        </span>
        </Link>
      </div>
      <div className="setting-btn sb-bottom">
        <div onClick={logout} className="btn">
        <span>
          <div className="sb-img"><img src="https://s.tykcdn.com/i/mq/images/logout.svg"/></div>
          Logout
        </span>
          <span>
          <img src="https://s.tykcdn.com/i/mq/images/chevron-right.svg"/>
        </span>
        </div>
      </div>
      <div className="copyright">&copy; Matchquiz, Triyakom 2021</div>
    </div>
  );
}