import {ISAT} from "./isat";
import {XL} from "./xl";
import {HTI} from "./hti";

export interface FAQItem {
  q: string,
  a: string
}

export interface SNKItem {
  html: string,
}

export interface Charging {
  reg: string,
  sms?: string,
  powerUp?: string,
  addPlay1?: string
  addPlay2?: string
}

export interface Join  {
  image: string,
  greeting: string,
  subtitle: string,
  smsMessage: string

}

export interface Operator {
  name: string,
  charging: Charging,
  join: Join,
  snkTitle?: string
}

export const getOperator = (name: string) => {
  if(name === 'isat') return ISAT;
  if(name === 'xl') return XL;
  if(name === 'hti') return HTI;
}

export const OperatorData = {
  operator: ISAT //default value
}
