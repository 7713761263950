import '../css/header-main.css';
import '../css/edit-profile.css';
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import axios from "axios";

type ChangeName = {
  name: string
}

export const EditProfilePage = () =>{
  const {register, handleSubmit, watch, errors} = useForm<ChangeName>();
  let history = useHistory();

  const[data, setData] = useState({
    msisdn: '',
    name: ''
  });

  useEffect(()=>{
    axios.get('/api/user/profile').then((response =>{
      setData(response.data.data);
    }));
  }, []);

  const submit = (_data: ChangeName)=>{
    axios.post('/api/user/change_name',{
      msisdn: data.msisdn,
      name: _data.name
    }).then(()=>{
      history.push('/setting');
    });

  }
    return (
      <div className="content edit-profile">
        <div className="main-head">
          <button onClick={()=>{history.goBack()}} className="btn"><img src="https://s.tykcdn.com/i/mq/images/back.svg"/></button>
          <h6>Edit Profile</h6>
        </div>
        <div className="edit-form">
          <form onSubmit={handleSubmit(submit)}>
          <div className="form-group">
            <label>Nomor Telepon :</label>
            <input name="msisdn" disabled={true} type="text" className="form-control" placeholder="Masukkan nomor" ref={register} defaultValue={data.msisdn}/>
          </div>
          <div className="form-group">
            <label>Nama :</label>
            <input name="name" type="text" className="form-control" placeholder="Masukkan nama" defaultValue={data.name} ref={register({required: true})} />
            {errors.name &&
            <div style={{color: '#ff0000', margin: '.5em'}}>*) This field is required</div>
            }
          </div>

          <button className="btn" type={"submit"}>GANTI</button>
          </form>
        </div>
        <div className="copyright">&copy; Matchquiz, Triyakom 2021</div>
      </div>
    );
}