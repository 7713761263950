import '../css/header.css';
import '../css/question-main.css';
import React, {Component} from "react";
import axios from "axios";
import {AnswerRequest, MQResponse, QuestionResponse, UserData} from "../Pojo";
import {PointHeader} from "../component/PointHeader";
import {QuestionFragment} from "../component/QuestionFragment";
import {RouteComponentProps} from "react-router-dom";
import {answerRequest, IdRouteProp} from "../Data";
import {QuestLoading} from "../component/QuestLoading";


type QuestState = {
  quest?: QuestionResponse,
  user?: UserData,
  time: boolean,
  fifty: boolean,
  power: number,
  index: number
}

export class QuestionPage extends Component<{} & RouteComponentProps<IdRouteProp>, QuestState> {
  questComponent: any;

  answerRequest: AnswerRequest = {
    ps: '',
    choices: []
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    axios.get<MQResponse>('/api/quest/get/' + this.props.match.params.id).then((response) => {
      let mq = response.data;
      console.log('>>', mq);

      if (mq.error) {
        //TODO popup error
      } else {
        let qr = mq.data as QuestionResponse;

        this.answerRequest.ps = qr.sessid;
        this.nextComponent(qr, mq.user, 0);

        this.setState({quest: qr, user: mq.user, power: 0, index: 0});

      }
    });
  }

  timeout = () => {
    this.next();
  }

  next = () => {
    if (this.state && this.state.quest && this.state.index < this.state.quest.quest.length - 1) {
      let i = this.state.index + 1;
      this.nextComponent(this.state.quest, this.state.user, i);
      this.setState({index: i});
    } else {
      this.submit();
    }
  }

  submit = ()=>{

    answerRequest.ps = this.answerRequest.ps;
    answerRequest.choices = this.answerRequest.choices;

    this.props.history.replace('/play/result');

  }

  nextComponent(qr: QuestionResponse, user?: UserData, index?: number) {
      this.questComponent = <QuestionFragment key={index}
        quest={qr.quest[index ? index : 0]}
        power={user ? user.power : 0}
        timeout={this.timeout}
        answer={this.answer}/>;

  }

  answer = (qids: string, aids: string) => {
    this.answerRequest?.choices.push({
      qids: qids,
      aids: aids
    });
    this.next();
  }


  render() {
    let ud = this.state ? this.state.user : undefined;
    if (this.questComponent) console.log('Q', this.questComponent[this.state.index]);
    return (
      <div className="content question-main">

        {(this.state && this.questComponent) ?
         <>
        <PointHeader title="MAIN" user={ud}/>
        {this.questComponent}
        </>
        :
          <QuestLoading/>
        }

      </div>
    );
  }
}