//TODO global static variable here
import {useLocation} from "react-router";

export const WebConfig = {
  operator: {
    isat: {
      bannerJoin: '',
    },
    hti: {
      bannerJoin: '',
    }
  }
}

export const resourcePrefix = 'https://tykcdn.com/img480/';
export const catsPrefix = 'https://s.tykcdn.com/i/mq/';




