import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import './css/bootstrap.min.css';
import './css/color-theme.css';
import './css/main.css';
import {HomePage} from "./page/homePage";
import {MainPage} from "./page/mainPage";
import {StorePage} from "./page/storePage";
import {LoginPage} from "./page/loginPage";
import {JoinPage} from "./page/joinPage";
import {SettingPage} from "./page/settingPage";
import {SNKPage} from "./page/snk";
import {FAQPage} from "./page/faq";
import {EditProfilePage} from "./page/editProfile";
import {LeaderboardPage} from "./page/leaderboardPage";
import {QuestionPage} from "./page/questionPage";
import {ResultPage} from "./page/resultPage";
import {GetReadyPage} from "./page/getReadyPage";
import {LivePage} from "./page/live/livePage";
import {WaitingLivePage} from "./page/live/waitingLive";
import {TestModal} from "./page/testModal";
import {TestAnimation} from "./page/testAnimation";
import {LiveHeader} from "./component/LiveHeader";
import {TestAnim2} from "./page/testAnim2";


function App() {
  return (
    <React.Fragment>


      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage/>
          </Route>

          <Route exact path="/home">
            <HomePage/>
          </Route>

          <Route path="/login">
            <LoginPage/>
          </Route>

          <Route exact path="/join">
            <JoinPage/>
          </Route>

          <Route exact path="/join/isat">
            <JoinPage operator='isat'/>
          </Route>

          <Route exact path="/join/hti">
            <JoinPage operator="hti"/>
          </Route>

          <Route path="/play/category">
            <MainPage/>
          </Route>

          <Route path="/play/ready">
            <GetReadyPage/>
          </Route>

          <Route path="/play/quest/:id" component={QuestionPage}/>

          <Route path="/play/result">
            <ResultPage/>
          </Route>

          <Route path="/leaderboard">
            <LeaderboardPage/>
          </Route>

          <Route path="/store">
            <StorePage/>
          </Route>

          <Route path="/setting" exact={true}>
            <SettingPage/>
          </Route>

          <Route path="/setting/snk">
            <SNKPage/>
          </Route>

          <Route path="/setting/faq">
            <FAQPage/>
          </Route>

          <Route path="/setting/profile">
            <EditProfilePage/>
          </Route>

          <Route path="/live/:id">
            <LivePage/>
          </Route>

          <Route path="/test/modal">
            <TestModal/>
          </Route>

          <Route path="/test/anim">
            <TestAnimation/>
          </Route>
          <Route path="/test/anim2">
            <TestAnim2/>
          </Route>


        </Switch>
      </Router>

    </React.Fragment>

  );
}

export default App;
