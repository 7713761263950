import '../css/join.css'
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Link} from 'react-router-dom';

import {motion} from "framer-motion/dist/framer-motion";
import {getOperator, Operator, OperatorData} from "../operator/Operator";
import {XL} from "../operator/xl";
import {ISAT} from "../operator/isat";
import {HTI} from "../operator/hti";

type ModalPopup = {
  show: string,
  active: string,

}
type JoinProp = {
  operator?: string
}
export function JoinPage({operator} : JoinProp) {

  console.log("OPP", operator);
  const [data, setData] = useState<ModalPopup>({
    show: operator ? '' : 'show',
    active: operator ? '': 'active',

  });

  const [display, setDisplay] = useState(operator === undefined);

  const hidePopup = (op: Operator) => {
    OperatorData.operator = op;
    setData({
      show: '',
      active: '',
    });
    setTimeout(() => {
      setDisplay(false)
    }, 600);
  }

  const charging = () => {
    window.location.href = OperatorData.operator.charging.reg;
  }

  const variants = {
    open: {scale: 1, opacity: 1},
    close: {
      scale: 0,
      opacity: 0.5,
      transition: {
        duration: 0.5,
      },
    },
  }

  useEffect(()=>{
    console.log(operator);
    if(operator){
      let op = getOperator(operator);
      if(op) {
        OperatorData.operator = op;
      }
    }
  },[operator]);

  return (
    <div>
      <div className="content join">
        <div className="join-wrapper">
          <div className="join-banner">
            <img src={OperatorData.operator.join.image}/>
            {/* <img src="http://tykcdn.com/img500/match_quiz/web/Banner_Join-Page_ramadhan.png"/> */}
          </div>
          <div className="join-inner">
            <h5>{OperatorData.operator.join.greeting}</h5>
            <h6>{OperatorData.operator.join.subtitle}</h6>
            <div className="ji-wrapper">
              <button onClick={charging} className="btn join-btn-join">GABUNG</button>
              <div className="join-or"><span>Or</span></div>
              <div className="jiw-text" dangerouslySetInnerHTML={{__html: OperatorData.operator.join.smsMessage}}>
              </div>
            </div>
            <Link to={'/login'} className="btn join-btn-signin">SIGN IN</Link>
          </div>
          <div className="join-bottom">
            <Link to={'setting/snk?o=' + OperatorData.operator.name} className="btn">
              <span>
                <div className="jb-img">
                  <img src="https://s.tykcdn.com/i/mq/images/snk.svg"/></div>
                Syarat &amp; Ketentuan
              </span>
              <span>
                <img src="https://s.tykcdn.com/i/mq/images/chevron-right.svg"/>
              </span>
            </Link>
            <Link to={'/setting/faq?o=' + OperatorData.operator.name} className="btn">
              <span>
                <div className="jb-img"><img src="https://s.tykcdn.com/i/mq/images/faq.svg"/></div>
                FAQ
              </span>
              <span>
                <img src="https://s.tykcdn.com/i/mq/images/chevron-right.svg"/>
              </span>
            </Link>
          </div>
        </div>
        <div className="copyright">&copy; Matchquiz, Triyakom 2023</div>
      </div>

      {display &&
      <div
          className={"modal-outer " + data.active}>
          <div style={{'display': data.show === '' ? 'block' : 'none'}}
               className={"modal-backdrop fade" + (data.show ? ' show' : '')}></div>

          <div
              className={"modal modal-join fade " + (display ? 'show' : '')} id="popupJoin">
              <div className="modal-dialog modal-dialog-centered">
                  <motion.div
                      initial={'close'}
                      variants={variants}
                      animate={data.show ? 'open' : 'close'}
                      className="modal-content">
                      <div className='mj-wrapper'>
                          <div className="mj-inner">
                              <h5>Yuk Pilih Provider kamu</h5>
                              <div className="mji-wrapper">
                                  {/*<a href='#' onClick={() => {
                                    hidePopup(XL)
                                  }}>
                                      <img src="https://s.tykcdn.com/i/mq/images/xl.png"/>
                                  </a>*/}
                                  <div className='operator' onClick={() => {
                                    hidePopup(ISAT)
                                  }}>
                                      <img src="https://s.tykcdn.com/i/mq/web/Indosat.svg" alt="isat-icon"/>
                                  </div>
                                  <div className='operator' onClick={() => {
                                    hidePopup(HTI)
                                  }}>
                                      <img src="https://s.tykcdn.com/i/mq/web/Tri.svg" alt="hti-icon"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </motion.div>
              </div>
          </div>
      </div>
      }


    </div>
  );
}