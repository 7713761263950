import {LiveHeader} from "../../component/LiveHeader";

export const QuestionLivePage = () =>{
    return (
      <div className="content countdown">
        <LiveHeader title={'MAIN'} />

        <div className="question-wrapper">
          <div className="qw-top">
            <div className="timer">
              <h4></h4>
              <svg xmlns="http://www.w3.org/2000/svg">
                <circle id="circle" className="circle_animation" r="24" cy="29" cx="29"/>
              </svg>
            </div>
            <div className="qw-top-text">Apakah nama stadion kandang dari club sepak bola Real Madrid?</div>
          </div>
          <div className="qw-answer" id="myAnswer">
            <div className="qwa-item qwa-one">
              <div className="qwa-text">Stadion Gelora Bung Karno</div>
              <div className="qwa-char">
            <span className="qwa-char-user">
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar1.svg"/></div>
              <div className="qwa-char-name">johnmyer</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar2.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar3.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
                <span>
              <div className="qwa-char-img">
                <div className="qwa-cm-inner">+8</div>
              </div>
            </span>
              </div>
            </div>
            <div className="qwa-item qwa-two correct">
              <div className="qwa-text">Stadion Gelora Bung Karno</div>
              <div className="qwa-char">
            <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar2.svg"/></div>
              <div className="qwa-char-name">johnmyer</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar4.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar1.svg"/></div>
              <div className="qwa-char-name">johasdasdasdasd</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar3.svg"/></div>
              <div className="qwa-char-name" id="text">johasdasdasd</div>
            </span>
              </div>
            </div>
            <div className="qwa-item qwa-three wrong">
              <div className="qwa-text">Stadion Gelora Bung Karno</div>
              <div className="qwa-char">
            <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar3.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar1.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
              </div>
            </div>
            <div className="qwa-item qwa-four wrong">
              <div className="qwa-text">Stadion Gelora Bung Karno</div>
              <div className="qwa-char">
            <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar2.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
                <span>
              <div className="qwa-char-img"><img
                src="https://s.tykcdn.com/i/mq/images/avatar4.svg"/></div>
              <div className="qwa-char-name">joh</div>
            </span>
              </div>
            </div>

            <div className="rocket-area">
              <div className="rocket to-area-four">
                <div className="rocket-img" id="Rocket"><img
                  src="https://s.tykcdn.com/i/mq/images/rocket.svg"/></div>
                <div className="explosion-img" id="Explo"><img
                  src="https://s.tykcdn.com/i/mq/images/explosion.svg"/></div>
              </div>
            </div>
          </div>
        </div>

      </div>

    )
}