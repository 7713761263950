import '../css/header-main.css';
import '../css/text.css';
import {useHistory, useLocation} from "react-router";
import {useEffect, useState} from "react";
import axios from "axios";
import {MQResponse} from "../Pojo";
import {FaqItem} from "../component/faqItem";

export const FAQPage = () => {
  const history = useHistory();

  const [data, setData] = useState({
    title: '',
    text: ''
  });

  const operator = new URLSearchParams(useLocation().search).get('o');

  let NEWLINE: string = '\r\n';

  useEffect(() => {
    let url = '/api/faq?o=' + operator;
    axios.get(url).then((data) => {
      let response: MQResponse = data.data;
      checkDoubleLine(response.data.text);
      setData(response.data);
    });
  }, []);

  function checkDoubleLine(text: string){
    if(text.indexOf('\n\n') > 0){
      NEWLINE = '\n';
    }
  }

  return (
    <div className="content text text-faq">
      <div className="main-head">
        <button onClick={() => {
          history.goBack()
        }} className="btn"><img src="https://s.tykcdn.com/i/mq/images/back.svg"/></button>
        <h6>FAQ</h6>
      </div>
      <div className="text-bdy" style={{minHeight: '100vh'}}>
        {data.title && data.title.length > 1 &&
        <h5 style={{textAlign: 'center',
          lineHeight: 1.5,
          margin: '1em',
          fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: data.title}}></h5>
        }

        {data.text &&

          data.text.split(NEWLINE + NEWLINE).map((value, index) =>{
            let i = value.indexOf(NEWLINE);
            let q = value.substring(0, i);
            let a = value.substring(i + 1, value.length);

            return <FaqItem key={'faq'+ index} q={q} a={a}/>
          })
        }
      </div>
    </div>

  );
}