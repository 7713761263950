import React, {useEffect, useState} from "react";
import axios from "axios";
import {WaitingLivePage} from "./waitingLive";
import {QuestionLivePage} from "./questionLive";
import {LiveHeader} from "../../component/LiveHeader";
import {RouteComponentProps, useParams} from "react-router-dom";
import {MQResponse} from "../../Pojo";
import {LiveCheckResponse, LiveSession} from "./LivePojo";

let stats: string = "init";

interface Params{
  id: string
}

interface MyState{
  page: string,
  data: any
}

export const LivePage = ()=> {
  const [pageData, setPageData] = useState<MyState>({page: 'loading', data: ''});

  let params = useParams<Params>();


  const checkLive = ()=>{
    axios.get('/api/live/check/' + params.id).then((response) => {
      let mq = response.data as MQResponse;
      let error = mq.error;
      if(error){
        //TODO error
      }else {
        let cr: LiveCheckResponse = mq.data as LiveCheckResponse;
        //setPageData({page: cr.status, data: cr.session});
        setPageData({page: 'wait', data: cr.session});
      }
    });
  }

  useEffect(() => {
    if (stats === "init") {
      checkLive();
    }
  }, []);

  return (
    <>
      {pageData.page === 'wait' &&
        <WaitingLivePage type={'wait'} time={300} prize={pageData.data.hadiahImage}>
            <LiveHeader title={'MAIN'}/>
        </WaitingLivePage>
      }
      {pageData.page === 'ongoing' &&
        <QuestionLivePage/>
      }

    </>
  )

}