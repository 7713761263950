import '../css/slider-category.css';
import '../css/all-category.css';

import {Link} from 'react-router-dom';
import {PointHeader} from '../component/PointHeader';
import React, {useEffect, useState} from "react";
import axios from "axios";
import Flickity from 'react-flickity-component';

import {MainCategories, MQResponse} from "../Pojo";
import {catsPrefix, resourcePrefix} from "../Constant";
import {motion} from 'framer-motion';


export const MainPage = () => {

  const [data, setData] = useState<MQResponse>();

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  }

  const itemLeft = {
    visible: {
      opacity: 1, x: 0,
    },
    hidden: {
      opacity: 0, x: -100,
    },
  }

  const itemRight = {
    visible: {
      opacity: 1, x: 0,
    },
    hidden: {
      opacity: 0, x: 100,
    },
  }

  useEffect(() => {
    axios.get<MQResponse>("/api/main").then((response) => {
      setData(response.data);
    });
  }, []);

  let mainCat = data ? data.data as MainCategories : undefined;
  let liveId = mainCat ? mainCat.liveId : undefined;
  let user = data ? data.user : undefined;

  return (
    <div className="content category">
      <PointHeader title="MAIN" user={user}/>
      <div className="slider-category">
        <h5>KATEGORI BARU!</h5>

        <Flickity
          className={'carousel'}
          elementType={'div'}
          options={{"freeScroll": true, "contain": true, "prevNextButtons": false, "pageDots": false}}
        >

          {liveId &&
          <div className="carousel-cell">
              <Link to={"/live/" + liveId}>
                  <div className="sc-item sc-item-live">
                      <img src="https://s.tykcdn.com/i/mq/images/sample/sample-1.png"/>
                      <div className="red-live"><span></span>LIVE</div>
                  </div>
              </Link>
          </div>
          }

          {mainCat &&
          mainCat.header.map(((value, index) => {
            return <div className="carousel-cell">
              <Link key={'hd-' + index} to={'/play/ready?id=' + value.id}>
                <div className="sc-item">
                  <img src={catsPrefix + value.image}/>
                </div>
              </Link>
            </div>
          }))

          }
          <div className="last-slide"></div>

        </Flickity>

      </div>
      <div className="all-category">
        <div className="all-category-wrapper">
          <h5>SEMUA KATEGORI</h5>

          {(mainCat) ?
            (
              <motion.div className="all-category-inner"
                          initial="hidden"
                          animate="visible"
                          variants={list}
              >
                {
                  mainCat.cats.map(((value, index) => {
                    return (
                      <motion.a
                        variants={index % 2 == 0 ? itemLeft : itemRight}
                        style={{padding: '.55em .5em'}}
                          key={index} href={'/play/ready?id=' + value.id}>
                          <div className="ac-item">
                            <img src={catsPrefix + value.image}/>
                            {/*<div className="aci-title">BINATANG</div>*/}
                          </div>

                      </motion.a>
                    )
                  }))
                }
              </motion.div>
            ) :

            <div className="loading-content">
              <svg version="1.1" x="0px" y="0px"
                   viewBox="-25 0 100 100" enableBackground="new 0 0 0 0">
                <circle fill="var(--loader)" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1"/>
                </circle>
                <circle fill="var(--loader)" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2"/>
                </circle>
                <circle fill="var(--loader)" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3"/>
                </circle>
              </svg>
            </div>
          }

        </div>
      </div>
    </div>);

}