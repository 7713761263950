import {motion} from "framer-motion";

export const TestAnim2 = () => {
  return (
  <motion.div
    initial={{
      scale: 0, y : -250
    }}
    animate={{
      scale: 1,
      y: 0
    }}
    transition={{
      duration: 2,
      type: "spring", stiffness: 100, damping: 10
    }}

    style={{
      width: 200,
      height: 200,
      backgroundColor: '#ff8000'
    }}
  >
  </motion.div>
  )
}