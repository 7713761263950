import {PointHeader} from "../component/PointHeader";

import '../css/get-ready.css';

import axios from "axios";
import {MQResponse, UserData} from "../Pojo";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";

export const GetReadyPage = () => {

  let COUNTER = 5;

  const [counter, setCounter] = useState<number>(COUNTER);
  const [userData, setUserData] = useState<UserData>();
  const [btn, setBtn] = useState<string>('active');

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  let intervalNumber: number;
  let sessionId: string;

  useEffect(() => {
    console.log('>>')
    intervalNumber = window.setInterval(countdown, 1000);
    axios.get<MQResponse>('/api/quest/init').then((response) => {
      let mq = response.data;
      sessionId = mq.data;
      if (mq.user) {
        setUserData(mq.user);
        if(mq.user.power >= 0) {
          setBtn('');
        }
      }
    });

    return () => {
      stopCountdown();
    }
  }, []);

  const doublePoint = () => {
    if (userData && userData.power >= 2) {
      let dp =  {
        sessid: sessionId,
        type: 'double'
      };
      axios.post('/api/quest/powerup', dp);
      setBtn('active');
    }
  }

  const countdown = () => {
    console.log('> ', counter, COUNTER);
    COUNTER--;
    if (COUNTER == 0) {
      stopCountdown();
      history.replace('/play/quest/' + query.get('id'))
    }
    setCounter(COUNTER);
  }

  function stopCountdown() {
    window.clearInterval(intervalNumber);
  }

  return (
    <div className="content get-ready">
      <div className="sprite-container">
        <div className="sc-wrapper">
          <div className="sprite-coin"></div>
        </div>
        <div className="sc-wrapper">
          <div className="sprite-coin"></div>
        </div>
        <div className="sc-wrapper">
          <div className="sprite-coin"></div>
        </div>
        <div className="sc-wrapper">
          <div className="sprite-coin"></div>
        </div>
      </div>

      <PointHeader title={'MAIN'} user={userData}/>

      <div className="get-ready-top">
        <h3>GET READY</h3>
        <div className="grt-wrapper">
          <div className="grt-spread"></div>
          <div className="grt-gold">
            <div className="grt-time">{counter}</div>
          </div>
        </div>
      </div>
      <div className="get-ready-bottom">
        <h4>Aktifkan Double Points?</h4>
        <div className="grb-active">
          <img src="https://s.tykcdn.com/i/mq/images/power-tu.svg"/>
          <h1>+2</h1>
        </div>
        <button onClick={doublePoint} className={'btn gr-btn ' + btn}>AKTIFKAN</button>
      </div>
    </div>

  )
}