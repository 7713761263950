import {UserData} from "../Pojo";
import '../css/header.css';

type Props = {
  user?: UserData,
  title: string,
  player?: boolean,
  point?: boolean
}

export const LiveHeader: React.FC<Props> = ({title, user, player = 0, point = true}) => {
  return (
    <header>
      <h6>{title}</h6>
      <div className="header-inner">
        <span className="hi-yellow">
          <img
            src="https://s.tykcdn.com/i/mq/images/player.svg"/> {player} PLAYERS
        </span>
        <span className="hi-yellow"><img src="https://s.tykcdn.com/i/mq/images/point.svg"/> {point}</span>
      </div>
    </header>
  );
}