import React, {useState} from "react";
import '../css/header.css';
import '../css/topup.css';

export const StorePage = () => {
  const [power, setPower] = useState(false);
  const [play, setPlay] = useState(false);

  const togglePopupPower = () => {
    setPower(!power);
  }

  const togglePopupPlayTurn = () => {
    setPlay(!play);
  }

  return (
    <React.Fragment>
      <div className="content burst-outer topup-outer">
        <div className="burst">
          <div className="burst-inner"></div>
        </div>
        <div className="topup">
          <header>
            <h6>TOP UP</h6>
            <div className="header-inner">
              <span className="hi-yellow"><img src="https://s.tykcdn.com/i/mq/images/player.svg"/> 20 PLAYERS</span>
              <span className="hi-yellow"><img
                src="https://s.tykcdn.com/i/mq/images/point.svg"/> 3250</span>
            </div>
          </header>
          <div className="topup-wrapper">
            <div className="ti-head"></div>
            <div className="topup-body">
              <div className="topup-body-head">TAMBAH POWER UP DAN<br/>PLAY TURN KAMU!</div>
              <div className="topup-box">
                <div className="topup-box-head">
                  <h6>Tambah Power Up</h6>
                  <button onClick={togglePopupPower} className="btn"><img
                    src="https://s.tykcdn.com/i/mq/images/info.svg"/></button>
                </div>
                <h6>Sisa Power Up : <b>4 Power</b></h6>
                <div className="topup-box-wrapper">
              <span>
                <div className="tbw-top"><img
                  src="https://s.tykcdn.com/i/mq/images/power-tu.svg"/><h2>1X</h2></div>
                <div className="tbw-text">RP 1.100,-</div>
                <button className="btn btn-topup-power">TAMBAH</button>
              </span>
                  <span>
                <div className="tbw-top"><img
                  src="https://s.tykcdn.com/i/mq/images/power-tu.svg"/><h2>3X</h2></div>
                <div className="tbw-text">RP 2.200,-</div>
                <button className="btn btn-topup-power">TAMBAH</button>
              </span>
                </div>
              </div>
              <div className="topup-box">
                <div className="topup-box-head">
                  <h6>Tambah Play Turn</h6>
                  <button onClick={togglePopupPlayTurn} className="btn"><img
                    src="https://s.tykcdn.com/i/mq/images/info.svg"/></button>
                </div>
                <h6>Sisa Power Up : <b>4 Play Turn</b></h6>
                <div className="topup-box-wrapper">
              <span>
                <div className="tbw-top"><img
                  src="https://s.tykcdn.com/i/mq/images/heart-tu.svg"/><h2>1X</h2></div>
                <div className="tbw-text">RP 1.100,-</div>
                <button className="btn btn-topup-heart">TAMBAH</button>
              </span>
                  <span>
                <div className="tbw-top"><img
                  src="https://s.tykcdn.com/i/mq/images/heart-tu.svg"/><h2>3X</h2></div>
                <div className="tbw-text">RP 2.200,-</div>
                <button className="btn btn-topup-heart">TAMBAH</button>
              </span>
                </div>
              </div>
            </div>
            <div className="ti-foot"></div>
          </div>
        </div>
      </div>

      <div className={"modal-outer" + ((power || play) ? ' active' : '')}>
        <div style={{'display' : power || play ? 'block' : 'none'}} className={"modal-backdrop fade" + ((power || play) ? ' show' : '')}></div>

        <div style={{'display' : power ? 'block' : 'none'}} className={"modal modal-topup fade" + (power ? ' show' : '')} id="modalTopupPower" tabIndex={-1}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button onClick={togglePopupPower} className="btn btn-close-mt" type="button" data-dismiss="modal"
                      aria-label="Close"><img
                src="https://s.tykcdn.com/i/mq/images/close-popup-tu.svg"/></button>
              <h4>APA ITU POWER UP?</h4>
              <p>Dengan fitur power up ini kamu bisa kumpulin poin lebih banyak loh!</p><br/>
              <p>Power ini bisa kamu pakai untuk:</p>
              <ul>
                <li><b>1. Double Point : 2 Power</b> Kamu bisa melipat gandakan poinmu jika menjawab kuis dengan
                  benar.
                </li>
                <li><b>2. Tambah waktu 5 detik : 1 Power</b> Dengan tambahan 5 detik, kamu akan memiliki tambahan
                  waktu untuk menjawab kuis.
                </li>
                <li><b>3. Filter : 1 Power</b> Kamu bisa menghilangkan 2 jawaban salah dari pilihan jawaban.</li>
              </ul>
            </div>
          </div>
        </div>


        <div style={{'display' : play ? 'block' : 'none'}} className={"modal modal-topup fade" + (play ? ' show' : '')} id="modalTopupPlayTurn" tabIndex={-1} >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button onClick={togglePopupPlayTurn} className="btn btn-close-mt" type="button" data-dismiss="modal"
                      aria-label="Close"><img
                src="https://s.tykcdn.com/i/mq/images/close-popup-tu.svg"/></button>
              <h4>APA ITU PLAY TURN</h4>
              <p>Play Turn adalah kesempatan bermain. Kamu bisa menambah terus Play Turn kamu sampai dengan 50x dalam
                sehari</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}