import * as React from "react";
import {useForm} from "react-hook-form";

import '../css/login.css';
import axios from "axios";
import {MQResponse} from "../Pojo";
import {useHistory} from "react-router";
import {Link} from 'react-router-dom';
import { ModalPopup } from "../component/ModalPopup";
import { useState } from "react";

type LoginData = {
  msisdn: string;
  name: string;
}

export const LoginPage = () => {
  const {register, handleSubmit} = useForm<LoginData>();
  let history = useHistory();
  const [popUp, setPopUp] = useState(false);

  const submit = (data: LoginData) => {
    axios.post<MQResponse>('/api/user/login', data).then((response) => {
      let mq = response.data;
      if (mq.error) {
        togglePopUp();
      } else {
        history.push('/home');
      }
    });
  }

  function togglePopUp(){
    setPopUp(!popUp);
  }

  return (
    
    <div className="content login">
      <div className="login-logo">
        <img src="https://s.tykcdn.com/i/mq/images/mq-logo.svg"/>
      </div>
      <div className="login-wrapper">
        <h2>Welcome<br/>Back</h2>
        <form onSubmit={handleSubmit(submit)}>
          <input type="text" name="msisdn" ref={register({required: true})} className="form-control"
                 placeholder="Masukkan nomor"/>
          <input type="text" name="name" ref={register({required: true})} className="form-control"
                 placeholder="Masukkan nama"/>
          <button type="submit" className="btn login-btn-login">LOGIN</button>
        </form>
        <div className="login-or"><span>Or</span></div>
        <Link to='/join' className="btn login-btn-signup">SIGN UP</Link>
      </div>
      <div className="copyright">&copy; Matchquiz, Triyakom 2023</div>
      <ModalPopup title="Login Error" show={popUp} toggle={togglePopUp} text={"No Handphone tidak terdaftar"} />
    </div>
  )
}