import '../css/header.css';
import '../css/score.css';
import React, {useEffect, useState} from "react";
import {answerRequest} from "../Data";
import {useHistory} from "react-router";
import axios from "axios";
import {MQResponse, ResultResponse, UserData} from "../Pojo";
import {PointHeader} from "../component/PointHeader";
import {Link} from 'react-router-dom';

export const ResultPage = () => {
  const history = useHistory();

  const [data, setData] = useState<MQResponse>();


  useEffect(() => {
    if (answerRequest.ps == '') {
      //user akses langsung /result
      history.replace('/home');
    } else {
      axios.post<MQResponse>('/api/quest/result', answerRequest).then((response) => {
        answerRequest.ps = ''; //reset

        let mq = response.data;
        if (mq.error) {

        } else {
          let result: ResultResponse = mq.data;
          setData(mq);
        }
      });
    }
  }, []);

  let user = data ? data.user as UserData : undefined;
  let result = data ? data.data as ResultResponse : undefined;

  return (<div className="content score">
      <PointHeader title={'MAIN'} user={user}/>
      <div className="score-top">
        <div className="st-burst">
          <div className="st-burst-wrapper">
            <div className="st-burst-inner">
              <div className="stbi-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="score-total">
        <div className="score-total-top">
          <div className="st-circle">
          <span>
            <div className="st-circle-item">
              <div className="st-circle-inner">
                <div>{result ? result.score : 0}</div>
                <div>SKOR</div>
              </div>
            </div>
          </span>
            {result && result.dpoint &&
            <React.Fragment>
                <span>
                  <div className="st-circle-item">
                    <div className="st-circle-inner">
                      <div>{result.score * 2}</div>
                      <div>SKOR</div>
                    </div>
                  </div>
                </span>
                <div className="st-circle-x">
                    <div>x</div>
                    <div>2</div>
                </div>
            </React.Fragment>

            }

          </div>
        </div>
        <div className="score-total-bottom">
          <h5>Total Poin Kamu <b>{ user ? user.point : 0}</b></h5>
          <div className="st-box">
          <span>
            <img src="https://s.tykcdn.com/i/mq/images/check.svg"/>
            <h6>Benar : {result ? result.right : 0}</h6>
          </span>
            <span>
            <img src="https://s.tykcdn.com/i/mq/images/wrong.svg"/>
            <h6>Salah : {result ? result.wrong : 0}</h6>
          </span>
          </div>
        </div>
      </div>
      <div className="score-tb-head"><b>Top 5</b> minggu ini :</div>
      <div className="score-tb">
        <table>
          <tr>
            <th>No.</th>
            <th>Nama</th>
            <th>Skor</th>
          </tr>

          { result && result.ranks &&

            result.ranks.ranks.map((value, index) => {
              return <tr>
                <td>{value.no}.</td>
                <td>{value.name}</td>
                <td>{value.point}</td>
              </tr>
            })

          }
        </table>
        <Link to={result ? ('/play/ready?id=' + result.catid) : ''} className="btn btn-score-yellow">MAIN LAGI</Link>
        <div className="score-tb-button">
          <Link to="/play/category" className="btn">PILIH<br/>KATEGORI</Link>
          <Link to="/home" className="btn">MENU<br/>UTAMA</Link>
        </div>
      </div>
    </div>
  )
}