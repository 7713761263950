import React, {useState} from "react";
import {motion} from "framer-motion";
import {ModalPopup} from "../component/ModalPopup";

export const TestModal = () => {
  const [open, setOpen] = useState(false);


  const toggle = () => {
    setOpen(!open);
  }

  return (
    <>
      <button onClick={toggle}>SHOW</button>
      <ModalPopup show={open} toggle={toggle} title={'Hello Darling'} text={"<p>Blah blah blah</p>"}/>

    </>
  )
}