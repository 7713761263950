import {LiveHeader} from "../../component/LiveHeader";

import '../../css/waiting.css';
import {useEffect, useState} from "react";
import axios from "axios";

interface Props{
  type: string,
  prize: string,
  time: number
}

export const WaitingLivePage: React.FC<Props> = ({type, prize, time, children}) =>{

  const [timer, setTimer] = useState('00:00');
  let intv: number;

  const tick = ()=>{
    time --;
    if(time <= 0){
      window.clearInterval(intv);
      setTimer('00:00');
    }else{
      setTimer(toTime(time));
    }
  }

  function toTime (d: number){
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    return ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
  }

  useEffect(()=> {
    if (time > 0) {
      intv = window.setInterval(tick, 1000);
      setTimer(toTime(time));
    }

    return ()=>{
      window.clearInterval(intv);
    }
  }, []);

  return (
    <div className="content burst-outer waiting-outer">
      <div className="burst">
        <div className="burst-inner"></div>
      </div>
      <div className="waiting">
        {
          children
        }
        <div className="waiting-logo">
          <img src="https://s.tykcdn.com/i/mq/images/live-match-quiz.svg"/>
        </div>
        <div className="waiting-prize">
          <img alt="hadiah" src={prize}/>
        </div>

        { type == 'wait' &&
        <div className="waiting-box">
          <p>Siap-siap ya, sebentar lagi dimulai!</p>
          <div className="waiting-time">{timer}</div>
        </div>
        }
        { type == 'ongoing' &&
        <div className="waiting-box">
            <p>Sayang sekali sudah berjalan. Lain kali aja ya.</p>
        </div>
        }
        { type == 'none' &&
        <div className="waiting-box">
            <p>Tunggu Next Live ya.</p>
        </div>
        }

      </div>
    </div>


  )
}