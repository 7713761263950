import React, {useEffect, useState} from "react";
import {animate, motion, useAnimation, useMotionValue} from "framer-motion";

export const TestAnimation = () => {

  const variants = {
    open: { y: 0, opacity: 1, duration: 3 },
    closed: { y: '-100vh', opacity: 0, duration: 0.5 },
  }

  const [isOpen, setOpen] = useState(false);

  const toggle = ()=>{
    setOpen(!isOpen);
  }
  return (
    <>
      <div style={{display: 'block'}}>
      <motion.div
        initial={{
          y: '-100vh',
          opacity: 0
        }}
        animate={
          isOpen ? 'open': 'close'}
        variants={variants}
        className="box" style={{
        width: 400,
        height: 400,
        backgroundColor: '#ff0000',
      }}>
      </motion.div>

      <div style={{
        marginTop: '1em',
        padding: '1em 2em',
        borderRadius: 24,
        backgroundColor: '#34ef90',
        textAlign: "center",
      }} onClick={toggle}>TOGGLE</div>

      </div>
    </>
  )
}