import '../css/header-main.css';
import '../css/text.css';
import {useHistory, useLocation} from "react-router";
import {useEffect, useState} from "react";
import axios from "axios";
import {MQResponse} from "../Pojo";

export const SNKPage = () => {
  const history = useHistory();

  const [data, setData] = useState({
    title: '',
    text: ''
  });

  const operator = new URLSearchParams(useLocation().search).get('o');

  useEffect(()=>{
    let url = '/api/snk?o=' + operator;
    axios.get(url).then((data)=>{
      let response: MQResponse = data.data;
      setData(response.data);
    });
  }, []);

  return (
    <div className="content text">
      <div className="main-head">
        <button onClick={() => {
          history.goBack()
        }} className="btn"><img src="https://s.tykcdn.com/i/mq/images/back.svg"/></button>
        <h6>SYARAT &amp; KETENTUAN</h6>
      </div>
      <div className="text-bdy" style={{minHeight: '100vh'}}>
        {data.title && data.title.length > 1 &&
          <h5 style={{textAlign: 'center',
          lineHeight: 1.5,
          margin: '1em',
          fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: data.title}}></h5>
        }
        <div dangerouslySetInnerHTML={{__html: data.text}}></div>
      </div>
    </div>
  );
}