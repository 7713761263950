import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";

type Props = {
  show?: boolean,
  toggle: () => void,
  title: string,
  text: string
}

export const ModalPopup: React.FC<Props> = (
  {show = false, toggle, title, text}) => {

  const [display, setDisplay] = useState(show);

  useEffect(()=>{
    console.log('S', show)
    if(show) {
      setDisplay(show);
    }else if(display != show && display) {
      setTimeout(()=>setDisplay(false), 300);
    }
  }, [show]);

  const variants = {
    open: {scale: 1, opacity: 1 },
    close: {
      scale: 0,
      opacity: 0.5,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (

    <div
      className={"modal-outer " + (show ? 'active' : '')}>
      <div style={{'display': show ? 'block' : 'none'}} className={"modal-backdrop fade" + (show ? ' show' : '')}></div>

      <div
        className={"modal modal-topup fade " + (display ? 'show' : '')} >
        <div className="modal-dialog modal-dialog-centered">
          <motion.div
            initial={'close'}
            variants={variants}
            animate={show ? 'open' : 'close'}
            className="modal-content">
            <button onClick={toggle} className="btn btn-close-mt"><img
              src="https://s.tykcdn.com/i/mq/images/close-popup-tu.svg"/></button>
            <h4>{title}</h4>
            <div dangerouslySetInnerHTML={{__html: text}}></div>
          </motion.div>
        </div>
      </div>
    </div>


  )
}