
import {Link} from 'react-router-dom';
import {UserData} from "../Pojo";
import {useHistory} from "react-router";

type Props = {
  user?: UserData,
  title: string,
  power?: boolean,
  point?: boolean,
  live?: boolean
}

export const PointHeader: React.FC<Props> = ({title, user, power = true, point = true, live = true}) => {

  const history = useHistory();
  const back = ()=>{
    history.goBack();
  }

  return (
    <header>
      <button onClick={back} className="btn btn-main-back"><img src="https://s.tykcdn.com/i/mq/images/back-main.svg"/>
      </button>
      <h6>{title}</h6>
      <div className="header-inner">
        {power &&
        <span className="hi-green"><img
          src="https://s.tykcdn.com/i/mq/images/power-green.svg"/> {!user ? 0 : user.power} POWER</span>
        }
        {point &&
        <span className="hi-yellow"><img src="https://s.tykcdn.com/i/mq/images/point.svg"/> {!user ? 0 : user.point}</span>
        }
        {live &&
        <span className="hi-yellow"><img src="https://s.tykcdn.com/i/mq/images/life.svg"/> X{!user ? 0 : user.live}</span>
        }
        {/*<span className="hi-topup"><Link to="/store" className="btn"><img
          src="https://s.tykcdn.com/i/mq/images/topup.svg"/></Link></span>*/}
      </div>
    </header>
  );
}