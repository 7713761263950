import '../css/home-main.css';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Banner, Home, MQResponse} from "../Pojo";
import {useHistory} from "react-router";
import Flickity from "react-flickity-component";


export const HomePage = () => {

  const [data, setData] = useState<Home>();

  let history = useHistory();

  useEffect(() => {
    axios.get<MQResponse>('api/home').then((response) => {
      let resp = response.data;
      if (resp.error) {
        history.replace('/join');
      } else {
        let home = resp.data as Home;
        setData(home);
      }
    });
  }, []);


  return (
    <div className="content home-main">
      <div className="hm-logo">
        <img src="https://s.tykcdn.com/i/mq/images/mq-logo.svg"/>
      </div>
      <a href="" className="hm-banner">
        {(data && data.banner) &&
        <img src={data.banner.image}/>
        }
      </a>
      <div className="hm-btn-wrapper">
        <Link to="/play/category" className="btn btn-hm-main">MAIN LAGI</Link>
        <div className="hm-inner-btn-wrapper">
          <Link to="/leaderboard" className="btn">LEADERBOARD & HADIAH</Link>
          <Link to="/setting" className="btn btn-setting"><img
            src="https://s.tykcdn.com/i/mq/images/setting.svg"/></Link>
        </div>
      </div>
      {(data && data.promos) &&
      <div className="hm-carousel">
        <h6>Promo menarik lainnya :</h6>
        <Flickity
          className={'carousel'}
          elementType={'div'}
          options={{"freeScroll": true, "contain": true, "prevNextButtons": false, "pageDots": false}}
        >
          {
          data.promos.map((promo: Banner) => {
            return <div className="carousel-cell">
              <a href={promo.url}>
                <div className="hmc-item">
                  <img src={promo.image}/>
                </div>
              </a>
            </div>
          })
          }
        </Flickity>
      </div>
      }
    </div>
  );
}