import React, {useState} from "react";


type Props = {
  q: string,
  a: string
}

export const FaqItem: React.FC<Props> = ({q, a}) => {
  const [show, setShow] = useState('collapse');
  return (
    <div className="acc-item">
      <div onClick={()=>{
        setShow('collapse' === show ? 'show': 'collapse');
      }} className={"acc-i-head " + (show === 'collapse' ? 'collapsed' : '') } id="headingOne">
        <span>{q}</span>
        <span><img src="https://s.tykcdn.com/i/mq/images/chevron-down.png"/></span>
      </div>
      <div className={"acc-i-body "+ show} dangerouslySetInnerHTML={{__html: a}}>
      </div>
    </div>
  );
}