import {Operator} from "./Operator";

export const XL : Operator = {
  "name": "xl",
  "charging": {
    "reg": "http://www.gudangapp.com/Wap_action.jsp?content=REG+MQ1&dest=99386&success=http%3A%2F%2Fmatchquiz.id%2Flogin",
    "powerUp": "",
    "addPlay1": "",
    "addPlay2": ""
  },
  "join": {
    "image": "",
    "greeting": "",
    "subtitle": "",
    "smsMessage":""
  },
}